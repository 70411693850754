<template>
  [ {{ provider.toUpperCase() }}_LOGO_HERE ] Bewertung: {{ value }} / 10
</template>

<script>

export default {
  props: {
    provider: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    }
  }
  // TODO add provider logo + styling
}
</script>
