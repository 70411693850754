// TODO Development state only, remove after work is finished
/* eslint no-unused-vars: 0 */
'use strict'

const minArticleParaCountForRectangle = 3
const numArticleParaBlockBetweenRectangles = 0
const posArticleMobile2 = 2
const posListLatestMobile2 = 2
const posListLatestMobile3 = 14
let posListNativeCrossbarTop = 5
const spanGroupsListRectangle2 = 4
let dynamicAdSlotsEnabled = false

const isArticleOrArticleVersion = (pageType) => pageType === 'article' || pageType === 'articleversion'

function addNativeCrossbarTop (paragraphs, pageType) {
  let newParagraphs = []

  const addAdaNativeCrossbarParagraph = {
    paragraphType: 'adaNativeCrossbar',
    adaNativeCrossbar: { position: 'top' }
  }

  if (isArticleOrArticleVersion(pageType)) {
    newParagraphs = paragraphs
    newParagraphs.push(addAdaNativeCrossbarParagraph)
  } else {
    let count = 0
    let added = false

    if (dynamicAdSlotsEnabled) {
      posListNativeCrossbarTop = 4
    }

    paragraphs.forEach(function (value) {
      if ((!dynamicAdSlotsEnabled && value.paragraphType !== 'adaAdSlot') ||
        (dynamicAdSlotsEnabled && value.paragraphType === 'teaserGroup')) {
        count++
      }

      newParagraphs.push(value)

      if (count >= (posListNativeCrossbarTop - 1) && !added && !['horizontalLine', 'interline'].includes(value.paragraphType)) {
        newParagraphs.push(addAdaNativeCrossbarParagraph)
        added = true
      }
    })
  }

  return newParagraphs
}

function addTopmobile1 (paragraphs, pageType) {
  const newParagraphs = []
  let count = 0
  let added = 0

  const addParagraphMobile1 = {
    paragraphType: 'adaAdSlot',
    adaAdSlot: { slotName: 'mobile', slotId: 1 }
  }

  added = false
  paragraphs.forEach(function (value) {
    if (isArticleOrArticleVersion(pageType) || (!isArticleOrArticleVersion(pageType) && value.paragraphType === 'teaserGroup')) {
      count++
    }
    newParagraphs.push(value)
    if (count >= 1 && !added) {
      newParagraphs.push(addParagraphMobile1)
      added = true
    }
  })

  return newParagraphs
}

function addTopmobile (paragraphs, pageType) {
  const newParagraphs = []
  let count = 0
  let added = 0

  const addParagraphMobile2 = {
    paragraphType: 'adaAdSlot',
    adaAdSlot: { slotName: 'mobile', slotId: 2 }
  }
  const addParagraphMobile3 = {
    paragraphType: 'adaAdSlot',
    adaAdSlot: { slotName: 'mobile', slotId: 3 }
  }

  if (isArticleOrArticleVersion(pageType)) {
    added = false
    paragraphs.forEach(function (value) {
      if (value.paragraphType !== 'horizontalLine') {
        count++
      }

      newParagraphs.push(value)

      if (count >= (posArticleMobile2 - 1) && !added) {
        newParagraphs.push(addParagraphMobile2)
        added = true
      }
    })

    if (!dynamicAdSlotsEnabled) {
      newParagraphs.push(addParagraphMobile3)
    }
  } else {
    paragraphs.forEach(function (value) {
      if (value.paragraphType === 'teaserGroup') {
        const numTeaser = value.teaserGroup.teaserGroupType === 'bx-teaser-container--slider' ? 3 : value.teaserGroup.teasers.length
        newParagraphs.push(value)
        if (count >= posListLatestMobile2 && added === 0) {
          newParagraphs.push(addParagraphMobile2)
          added++
        }
        if (!dynamicAdSlotsEnabled && (count + numTeaser) > posListLatestMobile3 && added === 1) {
          newParagraphs.push(addParagraphMobile3)
          added++
        }
        count = count + numTeaser
      } else {
        if (count >= posListLatestMobile2 && added === 0) {
          newParagraphs.push(addParagraphMobile2)
          added++
        }

        newParagraphs.push(value)
        count++
      }
    })
  }

  return newParagraphs
}

function addOutstreamAd (paragraphs, placementLogic) {
  let newParagraphs = []

  const addParagraphOutstream = {
    paragraphType: 'adaAdSlot',
    adaAdSlot: { slotName: 'outstream_div', slotId: 0 }
  }

  if (isArticleOrArticleVersion(placementLogic)) {
    let added = false
    paragraphs.forEach(function (value) {
      newParagraphs.push(value)

      if (value.paragraphType === 'text' && !added) {
        newParagraphs.push(addParagraphOutstream)
        added = true
      }
    })

    if (!added) {
      newParagraphs.push(addParagraphOutstream)
    }
  } else {
    newParagraphs = paragraphs
  }

  return newParagraphs
}

function markParagraphForRectangle1 (paragraphs) {
  let count = 0
  paragraphs.forEach((value, index) => {
    if (value.paragraphType === 'teaserGroup') {
      count++
      if (count === 2) {
        paragraphs[index].enableRectangle1 = true
      }
    }
  })
  return paragraphs
}

function addRectangle (paragraphs, placementLogic, initialParagraphCount, rectangleCollectionRules, isAdvertorial) {
  let count = 0
  let fillCollection = false
  const newParagraphs = []
  const paragraphCollection = {
    paragraphType: 'paragraphCollection',
    triggerAdEmbed: 'AdaRectangle1',
    paragraphs: []
  }

  if (isArticleOrArticleVersion(placementLogic)) {
    count = -1
    paragraphs.forEach(function (value) {
      if (value.paragraphType !== 'adaAdSlot') {
        count++
      }
      // start condition
      if (!fillCollection && rectangleCollectionRules.rectangle1.start === count && value.paragraphType !== 'adaAdSlot' && value.paragraphType !== 'childTagsTeaserGroup') {
        paragraphCollection.paragraphs = []
        fillCollection = true
      }

      // put paragraph to collection or keep it stand-alone
      if (fillCollection) {
        paragraphCollection.paragraphs.push(value)
      } else {
        newParagraphs.push(value)
      }

      // stop condition
      if (fillCollection && rectangleCollectionRules.rectangle1.stop === count) {
        fillCollection = false
        newParagraphs.push(paragraphCollection)
      }
    })
  } else {
    // default based on pageType list
    paragraphs.forEach(function (value) {
      if (value.paragraphType !== 'adaAdSlot') {
        count++
      }

      // Conditions to Start filling the paragraph Collection with first paragraph for search
      if (count === 1 && (value.paragraphType === 'search' || (value.paragraphType === 'vueComponent' && value.vueComponent?.componentName === 'ParagraphTypeRecipeSearch'))) {
        paragraphCollection.paragraphs.push(value)
        newParagraphs.push(paragraphCollection)
        return
      }

      // Conditions to Stop filling the paragraph Collection
      // extended OR to ensure that MR1 Collection will be closed on structureterm advertorial pages while there is no native crossbar injected
      if (fillCollection && (value.paragraphType === 'adaNativeCrossbar' || (isAdvertorial && count >= posListNativeCrossbarTop))) {
        fillCollection = false
        newParagraphs.push(paragraphCollection)
      }

      // put paragraph to collection or keep it stand-alone
      if (fillCollection) {
        paragraphCollection.paragraphs.push(value)
      } else {
        newParagraphs.push(value)
      }

      // Conditions to Start filling the paragraph Collection
      if (!fillCollection && count === 1 && value.paragraphType !== 'adaAdSlot') {
        paragraphCollection.paragraphs = []
        fillCollection = true
      }
    })
  }
  // garbage collection of unclosed collections
  if (fillCollection) {
    fillCollection = false
    newParagraphs.push(paragraphCollection)
  }

  return newParagraphs
}

function addRectangle2 (paragraphs, placementLogic, initialParagraphCount, rectangleCollectionRules, isAdvertorial) {
  let count = 0
  let fillCollection = false
  const newParagraphs = []
  const paragraphCollection = {
    paragraphType: 'paragraphCollection',
    triggerAdEmbed: 'AdaRectangle2',
    paragraphs: []
  }

  if (isArticleOrArticleVersion(placementLogic)) {
    const collectionCountRectangle1 = rectangleCollectionRules.rectangle1.stop - rectangleCollectionRules.rectangle1.start
    rectangleCollectionRules.rectangle2.start = rectangleCollectionRules.rectangle2.start - collectionCountRectangle1
    rectangleCollectionRules.rectangle2.stop = rectangleCollectionRules.rectangle2.stop - collectionCountRectangle1

    count = -1
    paragraphs.forEach(function (value) {
      if (value.paragraphType !== 'adaAdSlot') {
        count++
      }
      // start condition
      if (!fillCollection && rectangleCollectionRules.rectangle2.start === count) {
        paragraphCollection.paragraphs = []
        fillCollection = true
      }

      // put paragraph to collection or keep it stand-alone
      if (fillCollection) {
        paragraphCollection.paragraphs.push(value)
      } else {
        newParagraphs.push(value)
      }

      // stop condition
      if (fillCollection && rectangleCollectionRules.rectangle2.stop === count) {
        fillCollection = false
        newParagraphs.push(paragraphCollection)
      }
    })
  } else {
    // get position of native crossbar top, we should not go higher collecting paragraphs
    // to prevent nesting of collections - based on known cases
    let posOfAdEmbedNativeCrossbarTop = initialParagraphCount - 1
    for (const [index, value] of paragraphs.entries()) {
      if (value.paragraphType === 'adaNativeCrossbar') {
        posOfAdEmbedNativeCrossbarTop = index
        break
      }
    }

    // ensure that MR2 Collection will be build on structureterm advertorial pages while there is no native crossbar injected
    if (isAdvertorial && posOfAdEmbedNativeCrossbarTop === (initialParagraphCount - 1)) {
      posOfAdEmbedNativeCrossbarTop = posListNativeCrossbarTop
    }

    let startCollectionOnParagraphNo = initialParagraphCount - spanGroupsListRectangle2
    let stopCollectionOnParagraphNo = initialParagraphCount - 1

    // if there is not enough space as configured, we'll recalc StartPosition - if possible
    // otherwise we won't place a R2 on the page
    if (startCollectionOnParagraphNo <= posOfAdEmbedNativeCrossbarTop) {
      if (stopCollectionOnParagraphNo > posOfAdEmbedNativeCrossbarTop) {
        startCollectionOnParagraphNo = posOfAdEmbedNativeCrossbarTop + 1
      } else {
        startCollectionOnParagraphNo = stopCollectionOnParagraphNo = null
      }
    }

    // default based on pageType list
    paragraphs.forEach(function (value) {
      if (!['adaAdSlot', 'adaNativeCrossbar'].includes(value.paragraphType)) {
        count++
      }
      // Conditions to Stop filling the paragraph Collection
      if (!fillCollection && count === startCollectionOnParagraphNo) {
        fillCollection = true
        paragraphCollection.paragraphs = []
      }

      // put paragraph to collection or keep it stand-alone
      if (fillCollection) {
        paragraphCollection.paragraphs.push(value)
      } else {
        newParagraphs.push(value)
      }

      // Conditions to Start filling the paragraph Collection
      if (fillCollection && count === stopCollectionOnParagraphNo) {
        newParagraphs.push(paragraphCollection)
        fillCollection = false
      }
    })
  }
  // garbage collection
  if (fillCollection) {
    newParagraphs.push(paragraphCollection)
    fillCollection = false
  }

  return newParagraphs
}

function checkArticleParagraphStructure (data, initialParagraphCount) {
  const paragraphs = data.body.paragraphs
  const paragraphStructure = []
  const paragraphBreaker = []
  let quizOrSliderExistsIndex = -1
  let rectangleCollectionRules = {
    rectangle1: {
      start: false,
      stop: false
    },
    rectangle2: {
      start: false,
      stop: false
    }
  }

  paragraphs.forEach(function (value, index) {
    // Breaking Paragraph Conditions
    if (value.paragraphType === 'slider' || value.paragraphType === 'quiz') {
      quizOrSliderExistsIndex = data.pageMeta.adSlots.info.quizOrSliderExistsIndex = index
    }
    if ((value.paragraphType === 'slider' && value.slider.layout && (value.slider.layout.size === 'xl' || value.slider.layout.size === 'xxl')) ||
        (value.paragraphType === 'image' && value.image.layout && (value.image.layout.size === 'xl' || value.image.layout.size === 'xxl'))) {
      paragraphStructure[index] = 'B'
      paragraphBreaker.push(index)
    } else {
      paragraphStructure[index] = 'C'
    }
  })

  if (initialParagraphCount < minArticleParaCountForRectangle) {
    // handle short article placement > allow lead image/video inclusion
    rectangleCollectionRules.rectangle1.start = 0
    rectangleCollectionRules.rectangle1.stop = initialParagraphCount - 1
    data.pageMeta.adSlots.rectangle2.enableInPageWrapper = true
  } else if (initialParagraphCount < (2 * minArticleParaCountForRectangle)) {
    rectangleCollectionRules.rectangle1.start = paragraphStructure[0] !== 'B' || paragraphs[0].paragraphType === 'slider' ? 0 : 1
    rectangleCollectionRules.rectangle1.stop = initialParagraphCount - 1
    data.pageMeta.adSlots.rectangle2.enableInPageWrapper = true
  } else {
    // there should be enough space (by paragraph count - not real length/height) to place two rectangles
    rectangleCollectionRules = defineRectangleCollectionRules(rectangleCollectionRules, initialParagraphCount, paragraphStructure, paragraphBreaker)
  }

  if (quizOrSliderExistsIndex >= 0 && quizOrSliderExistsIndex > rectangleCollectionRules.rectangle1.stop) {
    // if there is a slider and/or a quiz on the page we want to ensure the MR1 is aside, no matter where it is in the list
    // we need to ensure this for the reloadAdsOnInteraction Feature
    // in this case we have a slider or a quiz but its index is bigger than the last index of the rectangle1 collection,
    // so it's out of range and the collection has to be changed
    data.pageMeta.adSlots.info.originRectangleCollectionRules = JSON.stringify(rectangleCollectionRules)
    if (initialParagraphCount > quizOrSliderExistsIndex + minArticleParaCountForRectangle) {
      rectangleCollectionRules.rectangle1.stop = quizOrSliderExistsIndex
      rectangleCollectionRules.rectangle2.start = quizOrSliderExistsIndex + 1
      rectangleCollectionRules.rectangle2.stop = initialParagraphCount - 1
      data.pageMeta.adSlots.rectangle2.enableInPageWrapper = false
    } else {
      rectangleCollectionRules.rectangle1.stop = initialParagraphCount - 1
      rectangleCollectionRules.rectangle2.start = false
      rectangleCollectionRules.rectangle2.stop = false
      data.pageMeta.adSlots.rectangle2.enableInPageWrapper = true
    }
  }

  return rectangleCollectionRules
}

function defineRectangleCollectionRules (rectangleCollectionRules, initialParagraphCount, paragraphStructure, paragraphBreaker, noRectangle2 = false) {
  const calcCollectionSize = Math.ceil((initialParagraphCount - paragraphBreaker.length - numArticleParaBlockBetweenRectangles) / 2)
  if (paragraphBreaker.length === 0 || (paragraphBreaker.length === 1 && paragraphBreaker[0] === 0)) {
    if (noRectangle2) {
      rectangleCollectionRules.rectangle1.start = 0
      rectangleCollectionRules.rectangle1.stop = initialParagraphCount - 1
    } else {
      rectangleCollectionRules.rectangle1.start = 0
      rectangleCollectionRules.rectangle1.stop = rectangleCollectionRules.rectangle1.start + calcCollectionSize - 1
      rectangleCollectionRules.rectangle2.start = rectangleCollectionRules.rectangle1.stop + numArticleParaBlockBetweenRectangles + 1
      rectangleCollectionRules.rectangle2.stop = paragraphStructure.length - 1
    }
  } else {
    // define a breaker zone based on paragraph count centered
    const listCenter = Math.ceil((initialParagraphCount - numArticleParaBlockBetweenRectangles) / 2)
    let paraZoneBreaker = listCenter
    // consider minArticleParaCountForRectangle space for R1+2
    const halfBlockScope = calcCollectionSize - minArticleParaCountForRectangle - 1
    // define zone Start and Stop count
    const blockZoneStart = listCenter - halfBlockScope
    const blockZoneStop = listCenter + halfBlockScope
    const zoneBreaker = []
    // loopkup existing breaker para in zone
    paragraphBreaker.forEach(function (value, index) {
      if (value >= blockZoneStart && value <= blockZoneStop) {
        zoneBreaker.push(value)
      }
    })

    // zone breaker found, so we use the latest to give R1 more span than R2
    // if no zone breaker were found, we use math center para to switch between R1 + R2 as defined on top
    if (zoneBreaker.length > 0) {
      paraZoneBreaker = zoneBreaker.pop()
    }

    rectangleCollectionRules.rectangle1.start = 0
    rectangleCollectionRules.rectangle1.stop = paraZoneBreaker - 1

    if (!noRectangle2) {
      rectangleCollectionRules.rectangle2.start = paraZoneBreaker + 1
      rectangleCollectionRules.rectangle2.stop = paragraphStructure.length - 1
    }
  }

  return rectangleCollectionRules
}

function RenamePageableTeaserGroupToTeaserGroup (paragraph) {
  paragraph.paragraphType = 'teaserGroup'
  paragraph.teaserGroup = paragraph.pageableTeaserGroup
  delete paragraph.pageableTeaserGroup
}

function splitStructureTermTeaserGroups (data) {
  const maxTeaserCountPerGroup = {
    'bx-teaser-container--full-width-two-cols': 2,
    'bx-teaser-container--full-width': 1,
    'bx-teaser-container--one-half-width': 2,
    'bx-teaser-container--one-third-width': 3,
    'bx-teaser-container--one-fourth-width': 4,
    'bx-teaser-container--one-sixth-width': 6,
    'bx-teaser-container--one-half-width-two-cols': 1,
    'bx-teaser-container--one-half-width-text-only': 2
  }

  let allowedTeaserCount = 0
  const paragraphs = data.body.paragraphs
  const newParagraphs = []

  paragraphs.forEach(function (paragraph) {
    // Add Logic based on teaserGroup Paragraphs, so we'll rename this group
    // so the given ad logic will affect also pages with pagination
    if (paragraph.paragraphType === 'pageableTeaserGroup') {
      RenamePageableTeaserGroupToTeaserGroup(paragraph)
    }
    if (paragraph.paragraphType === 'teaserGroup') {
      const groupTypeClasses = paragraph.teaserGroup.teaserGroupType.split(' ')
      groupTypeClasses.forEach(function (paragraph) {
        if (maxTeaserCountPerGroup[paragraph] !== undefined) {
          allowedTeaserCount = maxTeaserCountPerGroup[paragraph]
        }
      })

      const groupTeaser = paragraph.teaserGroup.teasers
      let teaserCount = 0
      let newTeaserGroup = {}
      let groupMask = paragraph
      groupMask.teaserGroup.teasers = []

      groupTeaser.forEach(function (teaser) {
        teaserCount++
        if (teaserCount === 1) {
          // for the first split group we use the mask from the given group
          // to keep existing properties which we'll skip for further splits
          newTeaserGroup = groupMask
        }

        newTeaserGroup.teaserGroup.teasers.push(teaser)

        if (teaserCount === allowedTeaserCount) {
          newParagraphs.push(newTeaserGroup)
          // for further splits we use a minimum skeleton and only keep
          // the groupType
          groupMask = {
            paragraphType: 'teaserGroup',
            teaserGroup: {
              teaserGroupType: newTeaserGroup.teaserGroup.teaserGroupType,
              teasers: []
            }
          }
          teaserCount = 0
        }
      })
      // if the given amount of teasers can't be split in equal groups
      // we'll have to add the unclosed group
      // TODO fits for madame, for later usage we need to modify the teaserGroupType so the group fits to the num of teaser
      if (teaserCount !== 0) {
        newParagraphs.push(newTeaserGroup)
      }
    } else {
      newParagraphs.push(paragraph)
    }
  })

  data.body.paragraphs = newParagraphs
  return data
}

function embedAds (data, rsConfig) {
  if (!data.body.paragraphs || !!data.metaData?.adaAutoNative) {
    return data
  }
  dynamicAdSlotsEnabled = rsConfig?.adSlotUsage?.dynamicAdSlots.enabled || false
  // check if we've already embedded dynamic ad-slots
  if (data.pageMeta.adSlots === undefined) {
    data.pageMeta.pageType = data.pageMeta.pageType.toLowerCase()
    data.pageMeta.adSlots = {
      embedded: false,
      info: {},
      rectangle2: {
        enableInPageWrapper: false
      }
    }
    if (data.pageMeta.pageType === 'structureterm' && data.pageMeta.page > 1) {
      // rename pageable teaser groups
      data = splitStructureTermTeaserGroups(data)
    }
    let rectangleCollectionRules = false
    const placementLogic = data.metaData?.behaveLikeArticle ? 'article' : data.pageMeta.pageType
    const adSlotsInfo = []
    const initialParagraphCount = data.pageMeta.adSlots.info.initialParagraphCount = data.body.paragraphs.length

    const overallCounter = -1
    for (let i = 0; i < initialParagraphCount; i++) {
      data.body.paragraphs[i].listOrderPosition = i
    }

    if (isArticleOrArticleVersion(placementLogic)) {
      // analyse paragraph structure for rectangle placement/collection grouping on article pages
      rectangleCollectionRules = checkArticleParagraphStructure(data, initialParagraphCount)
      data.pageMeta.adSlots.info.rectangleCollectionRules = JSON.stringify(rectangleCollectionRules)
    }

    // embed ad-slots based on ad-slot type
    if (!data?.metaData?.disallowPosterAds && !data?.metaData?.disallowAds && !dynamicAdSlotsEnabled) {
      data.body.paragraphs = addOutstreamAd(data.body.paragraphs, placementLogic)
    }

    if (dynamicAdSlotsEnabled) {
      // data.body.paragraphs = addTopmobile1(data.body.paragraphs, placementLogic)
      if (placementLogic === 'structureterm') {
        // data.body.paragraphs = markParagraphForRectangle1(data.body.paragraphs)
      }
    } else {
      data.body.paragraphs = addTopmobile(data.body.paragraphs, placementLogic)
      if (!data?.metaData?.advertorial) {
        data.body.paragraphs = addNativeCrossbarTop(data.body.paragraphs, placementLogic)
      }
      data.body.paragraphs = addRectangle(data.body.paragraphs, placementLogic, initialParagraphCount, rectangleCollectionRules, !!data.metaData.advertorial)
      if (data.pageMeta.adSlots.rectangle2.enableInPageWrapper === false) {
        data.body.paragraphs = addRectangle2(data.body.paragraphs, placementLogic, initialParagraphCount, rectangleCollectionRules, !!data.metaData.advertorial)
      }
    }

    data.pageMeta.adSlots.embedded = true
  }
  return data
}

const AdHandler = {
  methods: {
    embedAds (data, rsConfig) {
      return embedAds(data, rsConfig)
    }
  }
}

export default AdHandler
