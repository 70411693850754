<template>
  <div class="bx-recipe__amazon-food-link bx-typo--form">
    <span class="bx-typo--14px">Fehlt noch etwas?</span>

    <div>Amazon</div>
    <a href="https://www.amazon.de/Lebensmittel-Getr%C3%A4nke/b?ie=UTF8&node=340846031&linkCode=ll2&tag=lec-trk-2628-21&linkId=7e44fa5f762eaab01af3564330b4e884&language=de_DE&ref_=as_li_ss_tl"
       target="_blank"
       rel="sponsored"
       class="bx-form__button">
      Lebensmittel hier kaufen
    </a>
  </div>
</template>
