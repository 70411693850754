<template>
  <div :class="[
    `${cssPrefix}-rating`,
    `${cssPrefix}-rating--${size}`,
    `${cssPrefix}-typo--rating`,
    { [`${cssPrefix}-rating--editable`]: editable }]">
    <span :class="`${cssPrefix}-rating__stars`"
          data-tc="rating-stars">
      <i v-for="pos in 5"
         :key="pos"
         class="icon icon-star"
         :class="getStarClasses(pos - 1)"
         :tabindex="setTabindex"
         @mouseover="hover(pos)"
         @mouseleave="hover(null)"
         @click="editable && $emit('update:average', pos)"
         @keyup.enter="editable && $emit('update:average', pos)">
        <svg v-if="cssPrefix === 'bx'"><use href="/images/icon_sprite.svg#star" /></svg>
      </i>
    </span>
    <div>
      <span v-if="showAverage"
            :class="`${cssPrefix}-rating__average`"
            data-tc="rating-average">
        {{ averageString }} {{ averagePostfix }}
      </span>
      <span v-if="quantity"
            :class="`${cssPrefix}-rating__quantity`">
        {{ quantity }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { formatNumber } from '../../utils/numbers.ts'
import { usePageStore } from '../../stores/page'
import { useSessionStore } from '../../stores/session'

const starRanges = {
  percent25: {
    min: 0.15,
    max: 0.34
  },
  percent50: {
    min: 0.35,
    max: 0.64
  },
  percent75: {
    min: 0.65,
    max: 0.84
  }
}

export default {
  props: {
    average: {
      type: Number,
      default: 0
    },
    editable: {
      type: Boolean,
      default: false
    },
    averageFractionDigits: {
      type: Number,
      default: 0
    },
    averagePostfix: {
      type: String,
      default: ''
    },
    showAverage: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: String,
      default: null
    },
    cssPrefix: {
      type: String,
      default: 'bx'
    },
    size: {
      type: String,
      validator: (value) => ['small', 'medium', 'large', 'responsive'].includes(value),
      default: 'large'
    }
  },
  emits: ['update:average'],
  data () {
    return {
      hoveredPos: null
    }
  },
  computed: {
    ...mapState(usePageStore, ['brandFromStore']),
    ...mapState(useSessionStore, ['user']),
    averageString () {
      return formatNumber(this.average, this.averageFractionDigits)
    },
    setTabindex () {
      return this.editable ? '0' : null
    }
  },
  methods: {
    getStarClasses (pos) {
      const value = this.editable && this.hoveredPos !== null ? this.hoveredPos : this.average
      const isValueBetweenPosAndNext = value > pos && value < pos + 1
      const leftover = isValueBetweenPosAndNext ? ((value * 10) - (pos * 10)) / 10 : 0

      return {
        'icon-star--25': leftover >= starRanges.percent25.min && leftover < starRanges.percent50.min,
        'icon-star--50': leftover >= starRanges.percent50.min && leftover < starRanges.percent75.min,
        'icon-star--75': leftover >= starRanges.percent75.min && leftover <= starRanges.percent75.max,
        'icon-star--100': value >= pos + 1 || leftover > starRanges.percent75.max
      }
    },
    hover (pos) {
      if (this.editable) {
        this.hoveredPos = pos
      }
    }
  }
}
</script>
