<template>
  <video v-if="mediaId"
         ref="videoElement"
         preload="none"
         muted>
    <source :src="sourceSrc"
            type="video/mp4">
  </video>
</template>

<script>
import { useVideoTeaserQueueStore } from '../../stores/videoTeaserQueue'
import debounce from '../../mixins/debounce'
import deviceDetection from '../../mixins/device-detection'

export default {
  mixins: [debounce, deviceDetection],
  props: {
    mediaId: {
      type: String,
      default: null
    },
    teaserMouseState: {
      type: String,
      default: null
    },
    loading: {
      type: String,
      validator: (value) => ['lazy', 'eager'].includes(value),
      default: 'lazy'
    }
  },
  emits: ['videoMotionThumbnailSelfDisabled'],

  setup () {
    const videoTeaserQueue = useVideoTeaserQueueStore()
    return { videoTeaserQueue }
  },

  data () {
    return {
      posterWidth: 320,
      isVideoLoaded: false,
      sourceElement: null,
      observer: null,
      isInViewportCenter: false
    }
  },

  computed: {
    sourceSrc () {
      return `https://cdn.jwplayer.com/v2/media/${this.mediaId}/poster.mp4?width=${this.posterWidth}`
    }
  },

  watch: {
    teaserMouseState (newVal) {
      if (this.isDesktop()) {
        if (newVal === 'enter') {
          this.handleMouseEnter()
        } else if (newVal === 'leave') {
          this.handleMouseLeave()
        }
      }
    },
    isInViewportCenter (newVal) {
      if (!this.isDesktop()) {
        if (newVal) {
          this.handleVideoEnterCenter()
        } else {
          this.handleVideoLeaveCenter()
        }
      }
    }
  },

  mounted () {
    this.checkPosterWidth()

    this.setupIntersectionObserver()

    this.sourceElement = this.$refs.videoElement.querySelector('source')
    this.sourceElement.addEventListener('error', () => this.disableVideoMotionThumbnail())

    if (this.loading === 'eager') {
      this.loadVideo()
    } else {
      // add scroll listener to load video when it's near the viewport if lazy loading is enabled
      document.addEventListener('scroll', () => this.debounce(() => this.handleScroll(), 100), { passive: true })
    }

    if (this.$refs.videoElement) {
      this.$refs.videoElement.addEventListener('ended', this.handleVideoEnd)
    }
  },

  beforeUnmount () {
    document.removeEventListener('scroll', () => this.debounce(() => this.handleScroll(), 100), { passive: true })
    this.sourceElement.removeEventListener('error', () => this.disableVideoMotionThumbnail())

    if (!this.isDesktop() && this.mediaId) {
      this.videoTeaserQueue.removeFromQueue(this.mediaId)
    }

    if (this.observer) {
      this.observer.disconnect()
    }

    if (this.$refs.videoElement) {
      this.$refs.videoElement.removeEventListener('ended', this.handleVideoEnd)
    }
  },

  methods: {
    loadVideo () {
      this.isVideoLoaded = true
      this.$refs.videoElement.load()
    },

    checkPosterWidth () {
      if (this.$refs.videoElement?.offsetWidth > 640) {
        this.disableVideoMotionThumbnail()
      }
      this.posterWidth = this.$refs.videoElement?.offsetWidth <= 320 ? 320 : 640
    },

    disableVideoMotionThumbnail () {
      this.$refs.videoElement.style.display = 'none'
      this.$emit('videoMotionThumbnailSelfDisabled', true)
    },

    handleScroll () {
      // load video lazy when it's near the viewport
      const rect = this.$refs.videoElement?.getBoundingClientRect()
      if (!rect) return
      const threshold = 500 // 500 px before reaching the viewport
      if (rect.top < window.innerHeight + threshold && !this.isVideoLoaded) {
        this.loadVideo()
      }
    },

    // Desktop-specific handlers
    handleMouseEnter () {
      if (this.isDesktop()) {
        this.$refs.videoElement.play()
      }
    },

    handleMouseLeave () {
      if (this.isDesktop()) {
        this.$refs.videoElement.pause()
        this.$refs.videoElement.currentTime = 0
      }
    },

    setupIntersectionObserver () {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Video needs to be 50% visible to trigger
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          this.isInViewportCenter = entry.isIntersecting
        })
      }, options)

      if (this.$refs.videoElement) {
        this.observer.observe(this.$refs.videoElement)
      }
    },

    // Mobile/Tablet handlers
    handleVideoEnterCenter () {
      if (this.isVideoLoaded && this.$refs.videoElement && !this.isDesktop()) {
        this.videoTeaserQueue.addToQueue(this.mediaId, this.$refs.videoElement)
      }
    },

    handleVideoLeaveCenter () {
      if (!this.isDesktop()) {
        this.videoTeaserQueue.removeFromQueue(this.mediaId)
      }
    },

    handleVideoEnd () {
      if (this.isDesktop()) {
        // Desktop behavior - play twice
        const playCount = this.$refs.videoElement.dataset.playCount || 0
        if (playCount < 1) {
          this.$refs.videoElement.dataset.playCount = Number(playCount) + 1
          this.$refs.videoElement.currentTime = 0
          this.$refs.videoElement.play()
        }
      } else {
        // Mobile/Tablet behavior - managed by queue
        this.videoTeaserQueue.videoEnded(this.mediaId)
      }
    },

    isDesktop () {
      return this.deviceType === 'desktop'
    }
  }
}
</script>
