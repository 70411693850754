<template>
  <Collapsible content-id="unser-testbericht"
               :control-style="isInAutocomparison ? 'large' : 'small'"
               :label="overlineTestReport">
    <template #content>
      <template v-for="(product, productIndex) in unifiedProducts"
                :key="productIndex">
        <a target="_self"
           data-tc="product-anchor"
           class="bx-product__anchor bx-typo--link"
           @click="scrollToAnchor(product.link.url)">
          <strong>Hier lesen »</strong>
        </a>
      </template>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from './Collapsible.vue'

export default {
  components: {
    Collapsible
  },
  props: {
    unifiedProducts: {
      type: Array,
      default: () => []
    },
    isInAutocomparison: {
      type: Boolean,
      default: () => false
    },
    overlineTestReport: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    scrollToAnchor (anchor) {
      const element = document.getElementById(anchor)
      if (element) {
        const yOffset = -56
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  }
}
</script>
