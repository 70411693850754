import { defineStore } from 'pinia'

interface VideoQueueItem {
  mediaId: string,
  playCount: number,
  isPlaying: boolean,
  element: HTMLVideoElement
}

export const useVideoTeaserQueueStore = defineStore('videoTeaserQueue', {
  state: () => ({
    queue: [] as VideoQueueItem[],
    currentlyPlaying: null as string | null
  }),

  actions: {
    addToQueue (mediaId: string, element: HTMLVideoElement) {
      if (!this.queue.some(item => item.mediaId === mediaId)) {
        this.queue.push({
          mediaId,
          playCount: 0,
          isPlaying: false,
          element
        })
        this.processQueue()
      }
    },

    removeFromQueue (mediaId: string) {
      const index = this.queue.findIndex(item => item.mediaId === mediaId)
      if (index !== -1) {
        const item = this.queue[index]
        if (item.isPlaying) {
          item.element.pause()
          item.element.currentTime = 0
        }
        if (this.currentlyPlaying === mediaId) {
          this.currentlyPlaying = null
        }
        this.queue.splice(index, 1)
        this.processQueue()
      }
    },

    async processQueue () {
      if (this.currentlyPlaying || this.queue.length === 0) return

      const nextVideo = this.queue.find(item => item.playCount === 0) ||
                        this.queue.find(item => item.playCount === 1)

      if (!nextVideo) return

      this.currentlyPlaying = nextVideo.mediaId
      nextVideo.isPlaying = true
      nextVideo.element.currentTime = 0
      await nextVideo.element.play()
      nextVideo.playCount++
    },

    videoEnded (mediaId: string) {
      const video = this.queue.find(item => item.mediaId === mediaId)
      if (!video) return

      video.isPlaying = false
      video.element.currentTime = 0
      this.currentlyPlaying = null

      if (video.playCount >= 2) {
        this.removeFromQueue(mediaId)
      } else {
        this.processQueue()
      }
    }
  }
})
